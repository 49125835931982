<template>
  <crud-list
    :titulo-pesquisa="tituloPesquisa"
    :headers="headers"
    :items="items"
    :total-registros="totalRegistros"
    :paginacao.sync="pagination"
    :hide-headers="$vuetify.breakpoint.smAndDown"
    :label-new-button="labelNewButton"
    @buscar-event="buscar"
    @novo-event="novo"
  >
    <template slot="botaoDepoisAcaoTitulo">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="v-btn--simple"
            icon
            v-bind="attrs"
            @click="mostraFiltro = !mostraFiltro"
            v-on="on"
          >
            <v-icon>{{ mostraFiltro? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t( mostraFiltro ? 'padrao_esconder_filtro':'padrao_exibir_filtro') }}</span>
      </v-tooltip>
    </template>
    <template slot="antesDaDataTable">
      <v-row v-show="mostraFiltro">
        <v-col
          v-if="!idEmpresaCliente || idEmpresaCliente < 0"
          cols="12"
        >
          <empresa-cliente-auto-complete
            v-model="empresaClienteModel"
          />
        </v-col>
        <v-col
          :cols="6"
        >
          <caixa-select
            v-model="filtro.idCaixa"
            :readonly="!empresaClienteModelId"
            :id-empresa-cliente="empresaClienteModelId || idEmpresaCliente"
            adiciona-valor-todos
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          sm="4"
          cols="5"
        >
          <v-menu
            ref="menuDataMovimentacao"
            v-model="menuDataMovimentacao"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dataMovimentacaoFormatted"
                :label="$t('HistoricoMovimentacaoCaixaList.dataMovimentacao')"
                persistent-hint
                readonly
                append-icon="mdi-close"
                prepend-icon="mdi-calendar-clock"
                @blur="filtro.dataMovimentacao = parseDate(dataMovimentacaoFormatted)"
                v-on="on"
                @click:append="filtro.dataMovimentacao = null"
              />
            </template>
            <v-date-picker
              v-model="filtro.dataMovimentacao"
              no-title
              dark
              prepend-icon="mdi-calendar-text"
              @input="menuDataMovimentacao = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="5"
          sm="4"
        >
          <v-menu
            ref="menuDataMovimentacaoFim"
            v-model="menuDataMovimentacaoFim"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dataMovimentacaoFimFormatted"
                :label="$t('HistoricoMovimentacaoCaixaList.dataMovimentacaoFim')"
                persistent-hint
                readonly
                append-icon="mdi-close"
                prepend-icon="mdi-calendar-clock"
                @blur="filtro.dataMovimentacaoFim = parseDate(dataMovimentacaoFimFormatted)"
                v-on="on"
                @click:append="filtro.dataMovimentacaoFim = null"
              />
            </template>
            <v-date-picker
              v-model="filtro.dataMovimentacaoFim"
              no-title
              dark
              prepend-icon="mdi-calendar-text"
              @input="menuDataMovimentacaoFim = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="3"
        >
          <v-btn
            class="v-btn--simple"
            color="primary"
            outlined
            :icon="$vuetify.breakpoint.smAndDown"
            @click="buscar"
          >
            <span v-if="!$vuetify.breakpoint.smAndDown">{{ $t('padrao_pesquisar') }}</span>
            <v-icon>{{ 'mdi-magnify' }}</v-icon>
          </v-btn>
        </v-col>
        <hr v-show="mostraFiltro">
      </v-row>
    </template>
    <template
      slot="items"
      slot-scope="{ item }"
    >
      <tr>
        <td v-if="!$vuetify.breakpoint.smAndDown">
          {{ item.item.caixa }}
        </td>
        <td v-if="$vuetify.breakpoint.smAndDown">
          <table>
            <tr>
              <td>
                <span class="pr-2 bold">
                  {{ $t('HistoricoMovimentacaoCaixaList.caixa') }}
                </span>
              </td><td>
                {{ item.item.caixa }}
              </td>
            </tr>
            <tr>
              <td>
                <span class="pr-2 bold">
                  {{ $t('HistoricoMovimentacaoCaixaList.valorMovimentado') }}
                </span>
              </td><td v-money="{simbolo:'R$', valor:item.item.valorMovimentado}" />
            </tr>
          </table>
        </td>
        <td
          v-if="!$vuetify.breakpoint.smAndDown"
          v-money="{simbolo:'R$', valor:item.item.valorMovimentado}"
          class="text-center"
        />
        <td
          v-if="!$vuetify.breakpoint.smAndDown"
          v-data-formatada
        >
          {{ item.item.createdAt }}
        </td>
        <td v-if="$vuetify.breakpoint.smAndDown">
          <table>
            <tr>
              <td>
                <span class="pr-2 bold">
                  {{ $t('HistoricoMovimentacaoCaixaList.origemMovimentacao') }}
                </span>
              </td>
              <td>
                {{ $t('HistoricoMovimentacaoCaixaList.origemMovimentacao_' + item.item.origemMovimentacao) }}
                <v-tooltip
                  v-if="mostraBotaoRelacionamento(item.item)"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      slot="activator"
                      class="v-btn--simple"
                      color="success"
                      icon
                      @click="abrirRelacionamento(item)"
                      v-on="on"
                    >
                      <v-icon color="success">
                        mdi-set-center
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('HistoricoMovimentacaoCaixaList.abrir_relacionamento') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>
                <span class="pr-2 bold">
                  {{ $t('HistoricoMovimentacaoCaixaList.usuario') }}
                </span>
              </td>
              <td>{{ item.item.user }}</td>
            </tr>
            <tr>
              <td>
                <span class="pr-2 bold">
                  {{ $t('HistoricoMovimentacaoCaixaList.createdAt') }}
                </span>
              </td><td v-data-formatada>
                {{ item.item.createdAt }}
              </td>
            </tr>
          </table>
        </td>
        <td v-if="!$vuetify.breakpoint.smAndDown">
          {{ $t('HistoricoMovimentacaoCaixaList.origemMovimentacao_'+item.item.origemMovimentacao) }}
        </td>
        <td v-if="!$vuetify.breakpoint.smAndDown">
          {{ item.item.user }}
        </td>

        <td v-if="!idEmpresaCliente || idEmpresaCliente < 0">
          {{ item.item.empresaCliente }}
        </td>
      </tr>
    </template>
  </crud-list>
</template>

<script>

  import Servico from '../services/HistoricoMovimentacaoCaixaService'
  import { mapState } from 'vuex'
  import colunaEmpresaCliente from '@/utils/EmpresaClienteUtils'
  import EmpresaClienteAutoComplete from "@/views/EmpresaClienteAutoComplete"
  import EmpresaClientesService from '../services/EmpresaClientesService'
  import CaixaSelect from '../components/CaixaSelect'
  import DateUtils from "../utils/DateUtils"

  export default {
    components:{
      EmpresaClienteAutoComplete,
      CaixaSelect
    },
    data: () => ({
      totalRegistros: 0,
      pagination: {},
      items: [],
      loading: false,
      mostraFiltro: true,
      empresaClienteModel: null,
      menuDataMovimentacao: null,
      menuDataMovimentacaoFim: null,
      empresaClienteModelId: null,
      filtro: {
        idCaixa: null,
        idEmpresaCliente: null,
        dataMovimentacao: null,
        dataMovimentacaoFim: null,
      },
    }),
    computed: {
      ...mapState(['idEmpresaCliente']),
      tituloPesquisa: function () {
        return this.$i18n.t('HistoricoMovimentacaoCaixaList.tituloPesquisa')
      },
      labelNewButton: function () {
        return this.$i18n.t('HistoricoMovimentacaoCaixaList.ajuste_caixa')
      },
      dataMovimentacaoFormatted: function () {
        return DateUtils.formatDate(this.filtro.dataMovimentacao);
      },
      dataMovimentacaoFimFormatted: function () {
        return DateUtils.formatDate(this.filtro.dataMovimentacaoFim);
      },
      headers: function () {
        const retorno = [
          {
            text: this.$i18n.t('HistoricoMovimentacaoCaixaList.caixa'),
            value: 'caixa',
            sortable: false
          },
          {
            text: this.$i18n.t('HistoricoMovimentacaoCaixaList.saldo'),
            value: 'saldo',
            sortable: false
          },
          {
            text: this.$i18n.t('HistoricoMovimentacaoCaixaList.createdAt'),
            value: 'createdAt',
            sortable: false
          },
          {
            text: this.$i18n.t('HistoricoMovimentacaoCaixaList.origemMovimentacao'),
            value: 'origemMovimentacao',
            sortable: false
          },
          {
            text: this.$i18n.t('HistoricoMovimentacaoCaixaList.usuario'),
            value: 'usuario',
            sortable: false
          },
          {
            text: this.$i18n.t('padrao_acoes'),
            value: 'usuario',
            sortable: false
          }
        ]

        if (!this.idEmpresaCliente || this.idEmpresaCliente < 0) {
          retorno.push(colunaEmpresaCliente(this.$i18n.t('EmpresaClientesForm.tituloPagina')))
        }

        return retorno
      }
    },
    watch: {
      idEmpresaCliente (val) {
        if(val !== 0){
          this.buscar()
        }
      },
      empresaClienteModel(val) {
        if (val === null || !val) {
          this.empresaClienteModelId = null
          this.filtro.idEmpresaCliente = null
        } else {
          this.empresaClienteModelId = val.id
          this.filtro.idEmpresaCliente = val.id
        }
      },
    },
    async created () {
      this.$root.$emit('alteraMostraPesquisa', false)
      this.$root._events.pesquisa = []

      if(this.idEmpresaCliente && this.idEmpresaCliente > 0){
        this.empresaClienteModelId = this.idEmpresaCliente
      }

      if(this.$route.params.idCaixa){
        this.filtro.idCaixa = this.$route.params.idCaixa
        this.buscar()
      }
    },
    mounted() {

      if(localStorage.getItem('pagination-histmovcai-list')){
        this.pagination = JSON.parse(localStorage.getItem('pagination-histmovcai-list'))
      }

      if(localStorage.getItem('filtro-histmovcai-list') != null){
        this.filtro = JSON.parse(localStorage.getItem('filtro-histmovcai-list'))
        if(this.filtro.idEmpresaCliente && this.filtro.idEmpresaCliente > 0){
          EmpresaClientesService.findById(this.filtro.idEmpresaCliente)
            .then((res) => {
              this.empresaClienteModel = res.data
              this.started = true
              this.buscar()
            })
        }else{
          this.started = true
          this.buscar()
        }

      }else{
        const dt = new Date();
        const day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        let month = dt.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        this.filtro.dataEvento = `${dt.getFullYear()}-${month}-${day}`
        this.started = true
        this.buscar()
      }

    },
    methods: {

      mostraBotaoRelacionamento(item) {
        return item.origemMovimentacao === 0
      },

      abrirRelacionamento(item){
        let url = ''
        switch(item.origemMovimentacao){
          case 1:
            url = '/HistoricoMovimentacaoCaixaList/' + item.idDespesa
            break
          case 2: 
            url = '/eventoForm/' + item.idEvento + '/pagamento/' + item.idEventoPagamento
            break
          case 3:
            url = '/transferenciaCaixas/' + item.idTransferencia
        }
        this.$router.push({
          path: url
        })
      },
      efetuarPesquisa (pesquisa) {
        this.loading = true
        const param = {
          nome: pesquisa,
          idEmpresaCliente: this.idEmpresaCliente
        }

        Servico.findByParam(param, this.pagination)
          .then((dados) => {
            if (dados && dados.data && dados.data.rows) {
              this.items = dados.data.rows
            }
            if (dados && dados.data) {
              this.totalRegistros = dados.data.count
            } else {
              this.totalRegistros = 0
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      novo () {
        this.$router.push({
          path: '/histmovcaiForm/histmovcai'
        })
      },
      visualizar (id) {
        this.$router.push({
          path: `/histmovcaiForm/histmovcai/${id}`
        })
      },
      parseDate(date) {
        return DateUtils.parseDate(date);
      },
      buscar () {
        if(!this.idEmpresaCliente || this.idEmpresaCliente === 0){
          return
        }
        this.loading = true

        this.filtro.idEmpresaCliente =
          this.empresaClienteModelId || this.idEmpresaCliente
        
        const str = JSON.stringify(this.filtro)
        localStorage.setItem('filtro-histmovcai-list', str)
        localStorage.setItem('pagination-histmovcai-list', JSON.stringify(this.pagination))

        Servico.findByParam(this.filtro, this.pagination)
          .then((dados) => {
            if (dados && dados.data && dados.data.rows) {
              this.items = dados.data.rows
            }
            if (dados && dados.data) {
              this.totalRegistros = dados.data.count
            } else {
              this.totalRegistros = 0
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>

<style>
.nome-bold {
  font-weight: bold;
}
</style>
