import Api from '@/services/Api'

export default {
  insert (historicoMovimentacao) {
    return Api().post('histmovcaixa', historicoMovimentacao)
  },
  findByParam (param, pagination) {
    param = {
      param, pagination
    }
    return Api().post('histmovcaixa/findByParam', param)
  },
  findById (objeto) {
    const url = `histmovcaixa/findById/${objeto.id}`
    return Api().get(url)
  },

}
