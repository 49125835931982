var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('crud-list',{attrs:{"titulo-pesquisa":_vm.tituloPesquisa,"headers":_vm.headers,"items":_vm.items,"total-registros":_vm.totalRegistros,"paginacao":_vm.pagination,"hide-headers":_vm.$vuetify.breakpoint.smAndDown,"label-new-button":_vm.labelNewButton},on:{"update:paginacao":function($event){_vm.pagination=$event},"buscar-event":_vm.buscar,"novo-event":_vm.novo},scopedSlots:_vm._u([{key:"items",fn:function(ref){
var item = ref.item;
return [_c('tr',[(!_vm.$vuetify.breakpoint.smAndDown)?_c('td',[_vm._v(" "+_vm._s(item.item.caixa)+" ")]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('td',[_c('table',[_c('tr',[_c('td',[_c('span',{staticClass:"pr-2 bold"},[_vm._v(" "+_vm._s(_vm.$t('HistoricoMovimentacaoCaixaList.caixa'))+" ")])]),_c('td',[_vm._v(" "+_vm._s(item.item.caixa)+" ")])]),_c('tr',[_c('td',[_c('span',{staticClass:"pr-2 bold"},[_vm._v(" "+_vm._s(_vm.$t('HistoricoMovimentacaoCaixaList.valorMovimentado'))+" ")])]),_c('td',{directives:[{name:"money",rawName:"v-money",value:({simbolo:'R$', valor:item.item.valorMovimentado}),expression:"{simbolo:'R$', valor:item.item.valorMovimentado}"}]})])])]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('td',{directives:[{name:"money",rawName:"v-money",value:({simbolo:'R$', valor:item.item.valorMovimentado}),expression:"{simbolo:'R$', valor:item.item.valorMovimentado}"}],staticClass:"text-center"}):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('td',{directives:[{name:"data-formatada",rawName:"v-data-formatada"}]},[_vm._v(" "+_vm._s(item.item.createdAt)+" ")]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('td',[_c('table',[_c('tr',[_c('td',[_c('span',{staticClass:"pr-2 bold"},[_vm._v(" "+_vm._s(_vm.$t('HistoricoMovimentacaoCaixaList.origemMovimentacao'))+" ")])]),_c('td',[_vm._v(" "+_vm._s(_vm.$t('HistoricoMovimentacaoCaixaList.origemMovimentacao_' + item.item.origemMovimentacao))+" "),(_vm.mostraBotaoRelacionamento(item.item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"v-btn--simple",attrs:{"slot":"activator","color":"success","icon":""},on:{"click":function($event){return _vm.abrirRelacionamento(item)}},slot:"activator"},on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-set-center ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('HistoricoMovimentacaoCaixaList.abrir_relacionamento')))])]):_vm._e()],1)]),_c('tr',[_c('td',[_c('span',{staticClass:"pr-2 bold"},[_vm._v(" "+_vm._s(_vm.$t('HistoricoMovimentacaoCaixaList.usuario'))+" ")])]),_c('td',[_vm._v(_vm._s(item.item.user))])]),_c('tr',[_c('td',[_c('span',{staticClass:"pr-2 bold"},[_vm._v(" "+_vm._s(_vm.$t('HistoricoMovimentacaoCaixaList.createdAt'))+" ")])]),_c('td',{directives:[{name:"data-formatada",rawName:"v-data-formatada"}]},[_vm._v(" "+_vm._s(item.item.createdAt)+" ")])])])]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('td',[_vm._v(" "+_vm._s(_vm.$t('HistoricoMovimentacaoCaixaList.origemMovimentacao_'+item.item.origemMovimentacao))+" ")]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('td',[_vm._v(" "+_vm._s(item.item.user)+" ")]):_vm._e(),(!_vm.idEmpresaCliente || _vm.idEmpresaCliente < 0)?_c('td',[_vm._v(" "+_vm._s(item.item.empresaCliente)+" ")]):_vm._e()])]}}])},[_c('template',{slot:"botaoDepoisAcaoTitulo"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"v-btn--simple",attrs:{"icon":""},on:{"click":function($event){_vm.mostraFiltro = !_vm.mostraFiltro}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mostraFiltro? 'mdi-eye-off' : 'mdi-eye'))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( _vm.mostraFiltro ? 'padrao_esconder_filtro':'padrao_exibir_filtro')))])])],1),_c('template',{slot:"antesDaDataTable"},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.mostraFiltro),expression:"mostraFiltro"}]},[(!_vm.idEmpresaCliente || _vm.idEmpresaCliente < 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('empresa-cliente-auto-complete',{model:{value:(_vm.empresaClienteModel),callback:function ($$v) {_vm.empresaClienteModel=$$v},expression:"empresaClienteModel"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":6}},[_c('caixa-select',{attrs:{"readonly":!_vm.empresaClienteModelId,"id-empresa-cliente":_vm.empresaClienteModelId || _vm.idEmpresaCliente,"adiciona-valor-todos":""},model:{value:(_vm.filtro.idCaixa),callback:function ($$v) {_vm.$set(_vm.filtro, "idCaixa", $$v)},expression:"filtro.idCaixa"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"4","cols":"5"}},[_c('v-menu',{ref:"menuDataMovimentacao",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('HistoricoMovimentacaoCaixaList.dataMovimentacao'),"persistent-hint":"","readonly":"","append-icon":"mdi-close","prepend-icon":"mdi-calendar-clock"},on:{"blur":function($event){_vm.filtro.dataMovimentacao = _vm.parseDate(_vm.dataMovimentacaoFormatted)},"click:append":function($event){_vm.filtro.dataMovimentacao = null}},model:{value:(_vm.dataMovimentacaoFormatted),callback:function ($$v) {_vm.dataMovimentacaoFormatted=$$v},expression:"dataMovimentacaoFormatted"}},on))]}}]),model:{value:(_vm.menuDataMovimentacao),callback:function ($$v) {_vm.menuDataMovimentacao=$$v},expression:"menuDataMovimentacao"}},[_c('v-date-picker',{attrs:{"no-title":"","dark":"","prepend-icon":"mdi-calendar-text"},on:{"input":function($event){_vm.menuDataMovimentacao = false}},model:{value:(_vm.filtro.dataMovimentacao),callback:function ($$v) {_vm.$set(_vm.filtro, "dataMovimentacao", $$v)},expression:"filtro.dataMovimentacao"}})],1)],1),_c('v-col',{attrs:{"cols":"5","sm":"4"}},[_c('v-menu',{ref:"menuDataMovimentacaoFim",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('HistoricoMovimentacaoCaixaList.dataMovimentacaoFim'),"persistent-hint":"","readonly":"","append-icon":"mdi-close","prepend-icon":"mdi-calendar-clock"},on:{"blur":function($event){_vm.filtro.dataMovimentacaoFim = _vm.parseDate(_vm.dataMovimentacaoFimFormatted)},"click:append":function($event){_vm.filtro.dataMovimentacaoFim = null}},model:{value:(_vm.dataMovimentacaoFimFormatted),callback:function ($$v) {_vm.dataMovimentacaoFimFormatted=$$v},expression:"dataMovimentacaoFimFormatted"}},on))]}}]),model:{value:(_vm.menuDataMovimentacaoFim),callback:function ($$v) {_vm.menuDataMovimentacaoFim=$$v},expression:"menuDataMovimentacaoFim"}},[_c('v-date-picker',{attrs:{"no-title":"","dark":"","prepend-icon":"mdi-calendar-text"},on:{"input":function($event){_vm.menuDataMovimentacaoFim = false}},model:{value:(_vm.filtro.dataMovimentacaoFim),callback:function ($$v) {_vm.$set(_vm.filtro, "dataMovimentacaoFim", $$v)},expression:"filtro.dataMovimentacaoFim"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"v-btn--simple",attrs:{"color":"primary","outlined":"","icon":_vm.$vuetify.breakpoint.smAndDown},on:{"click":_vm.buscar}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('span',[_vm._v(_vm._s(_vm.$t('padrao_pesquisar')))]):_vm._e(),_c('v-icon',[_vm._v(_vm._s('mdi-magnify'))])],1)],1),_c('hr',{directives:[{name:"show",rawName:"v-show",value:(_vm.mostraFiltro),expression:"mostraFiltro"}]})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }